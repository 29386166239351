<div class="container">

  <header class="header">
    <h1 class="header-logo">
      <span>VI.BE</span>
    </h1>
    <button
      type="button"
      title="Sluiten"
      i18n-title
      class="header-closeButton"
      (click)="menuClosed.emit()">
      <i class="header-closeButton-icon fal fa-times"></i>
    </button>
  </header>

  <section
    class="content"
    [class.content--custom]="contentMenu?.length">

    <!-- Platform -->
    <ul class="menu">

      <!-- Default -->
      <ng-container *ngIf="!contentMenu?.length">

        <li class="menu-item">
          <a
            routerLink="/"
            class="menu-item-main"
            routerLinkActive="menu-item-main--active"
            (click)="menuClosed.emit()"
            i18n
          >Home</a>
        </li>
        <li class="menu-item">
          <a
            routerLink="/calls"
            class="menu-item-main"
            routerLinkActive="menu-item-main--active"
            (click)="menuClosed.emit()"
            i18n
          >Kansen</a>
        </li>
        <li class="menu-item">
          <a
            routerLink="/artists"
            class="menu-item-main"
            routerLinkActive="menu-item-main--active"
            (click)="menuClosed.emit()"
            i18n
          >Artiesten</a>
        </li>

        <ng-container *ngIf="!currentUser">
          <li class="menu-item">
            <a
              class="menu-item-main"
              routerLink="/auth/signin"
              routerLinkActive="menu-item-active"
              (click)="menuClosed.emit()"
              i18n
            >Log in</a>
          </li>
          <li class="menu-item">
            <a
              class="menu-item-main"
              routerLink="/auth/signup"
              routerLinkActive="menu-item-main--active"
              (click)="menuClosed.emit()"
              i18n
            >Registreer</a>
          </li>
        </ng-container>

        <ng-container *ngIf="currentUser">
          <li class="menu-item" *ngIf="currentUser?.roles?.includes('ADMIN')">
            <a
              routerLink="/admin"
              class="menu-item-main"
              routerLinkActive="menu-item-main--active"
              (click)="menuClosed.emit()"
              i18n
            >Admin</a>
          </li>

            <!-- Account -->
            <li class="menu-user menu-item">
              <a
                class="menu-item-main--preserveCasing"
                routerLink="/auth/account"
                routerLinkActive="menu-item-main--active"
                (click)="menuClosed.emit()">
                <div class="user" [title]="currentUserName + ' ' + currentUser.email">
                  <app-avatar
                    [user]="currentUser"
                    [light]="true"
                    class="user-avatar"
                  ></app-avatar>
                  <p class="user-name">{{currentUser.givenName}}</p>
                </div>
              </a>
            </li>

            <li class="submenu-item">
              <a
                routerLink="/auth/account"
                class="submenu-item-main"
                routerLinkActive="submenu-item-main--active"
                (click)="menuClosed.emit()"
                i18n
              >Account</a>
            </li>

            <li class="submenu-item">
              <a
                routerLink="/auth/account/playlists"
                class="submenu-item-main"
                routerLinkActive="submenu-item-main--active"
                (click)="menuClosed.emit()"
                i18n
              >Playlists</a>
            </li>

            <li class="submenu-item">
              <a
                routerLink="/auth/account/profiles"
                class="submenu-item-main"
                routerLinkActive="submenu-item-main--active"
                (click)="menuClosed.emit()"
                i18n
              >Profielen</a>
            </li>
            <ng-template [ngTemplateOutlet]="artistProfiles"></ng-template>
            <ng-template [ngTemplateOutlet]="organisationProfiles"></ng-template>

            <ng-container *ngIf="(currentUser?.invites?.items | userInvited:'ORGANISATION_CALL':true)?.length || currentUser?.acceptedOrganisationCalls?.items?.length">
              <li class="submenu-item">
                <span
                  class="submenu-item-main"
                  i18n
                >Kansen</span>
              </li>
              <ng-template [ngTemplateOutlet]="pendingCalls"></ng-template>
              <ng-template [ngTemplateOutlet]="acceptedCalls"></ng-template>
            </ng-container>

            <!-- Sign out -->
            <li class="menu-item">
              <button
                class="menu-item-main"
                (click)="signout()"
                i18n
              >Uitloggen</button>
            </li>
        </ng-container>
      </ng-container>

      <!-- Custom -->
      <li *ngIf="contentMenu?.length" class="menu-item">
        <a
          routerLink="/"
          class="menu-item-main"
          routerLinkActive="menu-item-main--active"
          (click)="menuClosed.emit()"
          i18n
        >Platform</a>
        <ul class="submenu">
          <li class="submenu-item">
            <a
              routerLink="/"
              class="submenu-item-main"
              routerLinkActive="submenu-item-main--active"
              (click)="menuClosed.emit()"
              i18n
            >Home</a>
          </li>
          <li class="submenu-item">
            <a
              routerLink="/calls"
              class="submenu-item-main"
              routerLinkActive="submenu-item-main--active"
              (click)="menuClosed.emit()"
              i18n
            >Kansen</a>
          </li>
          <li class="submenu-item">
            <a
              routerLink="/artists"
              class="submenu-item-main"
              routerLinkActive="submenu-item-main--active"
              (click)="menuClosed.emit()"
              i18n
            >Artiesten</a>
          </li>

          <!-- Current user -->
          <ng-container *ngIf="currentUser">

            <!-- Admin -->
            <li class="submenu-item" *ngIf="currentUser?.roles?.includes('ADMIN')">
              <a
                routerLink="/admin"
                class="submenu-item-main"
                routerLinkActive="submenu-item-main--active"
                (click)="menuClosed.emit()"
                i18n
              >Admin</a>
            </li>

            <!-- Account -->
            <li class="menu-user submenu-item">
              <a
                class="submenu-item-main--preserveCasing"
                routerLink="/auth/account"
                routerLinkActive="submenu-item-main--active"
                (click)="menuClosed.emit()">
                <div class="user" [title]="currentUserName + ' ' + currentUser.email">
                  <app-avatar
                    [user]="currentUser"
                    [light]="true"
                    class="user-avatar"
                  ></app-avatar>
                  <p class="user-name">{{currentUser.givenName}}</p>
                </div>
              </a>
            </li>

            <ng-template [ngTemplateOutlet]="artistProfiles"></ng-template>
            <ng-template [ngTemplateOutlet]="organisationProfiles"></ng-template>
            <ng-template [ngTemplateOutlet]="pendingCalls"></ng-template>
            <ng-template [ngTemplateOutlet]="acceptedCalls"></ng-template>

            <!-- Sign out -->
            <li class="submenu-item">
              <button
                class="submenu-item-main"
                (click)="signout()"
                i18n
              >Uitloggen</button>
            </li>
          </ng-container>

          <!-- Login / Register -->
          <ng-container *ngIf="!currentUser">
            <li class="submenu-item">
              <a
                class="submenu-item-main"
                routerLink="/auth/signin"
                routerLinkActive="submenu-item-active"
                (click)="menuClosed.emit()"
                i18n
              >Log in</a>
            </li>
            <li class="submenu-item">
              <a
                class="submenu-item-main"
                routerLink="/auth/signup"
                routerLinkActive="submenu-item-main--active"
                (click)="menuClosed.emit()"
                i18n
              >Registreer</a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>

    <!-- Editorial -->
    <ul *ngIf="contentMenu?.length" class="menu">
      <li
        *ngFor="let item of contentMenu"
        class="menu-item">
        <a
          *ngIf="item.routerLink"
          [routerLink]="item.routerLink || null"
          [routerLinkActive]="'menu-item-main--active'"
          class="menu-item-main--preserveCasing"
        >{{item.label}}</a>
        <a
          *ngIf="item.link && !item.routerLink"
          [href]="item.link"
          class="menu-item-main--preserveCasing"
        >{{item.label}}</a>
        <span
          *ngIf="!item.routerLink && !item.link"
          class="menu-item-main--preserveCasing"
        >{{item.label}}</span>
        <ul class="submenu" *ngIf="item.submenu?.length">
          <li
            *ngFor="let submenuItem of item.submenu"
            class="submenu-item">
            <a
              *ngIf="submenuItem.routerLink"
              [routerLink]="submenuItem.routerLink || null"
              [routerLinkActive]="'submenu-item-main--active'"
              [routerLinkActiveOptions]="{exact: submenuItem.routerLink === '/'}"
              class="submenu-item-main--preserveCasing"
            >{{submenuItem.label}}</a>
            <a
              *ngIf="submenuItem.link && !submenuItem.routerLink"
              [href]="submenuItem.link"
              class="submenu-item-main--preserveCasing"
            >{{submenuItem.label}}</a>
            <span
              *ngIf="!submenuItem.routerLink && !submenuItem.link"
              class="submenu-item-main--preserveCasing"
            >{{submenuItem.label}}</span>
          </li>
        </ul>
      </li>
    </ul>

    <footer class="footer">
      <ul
        *ngFor="let list of contentFooter?.lists"
        class="footer-list">
        <li
          *ngFor="let item of list"
          class="footer-list-li">
          <a
            *ngIf="item.link"
            href="{{item.link}}"
            target="_blank"
            rel="noopener"
          >{{item.label}}</a>
          <span
            *ngIf="!item.link"
          >{{item.label}}</span>
        </li>
      </ul>
    </footer>
  </section>

  <img *ngIf="contentImage" class="image" src="{{contentImage}}" loading="lazy" />
</div>

<ng-template #artistProfiles>
  <li class="submenu-item" *ngFor="let invite of (currentUser?.invites?.items | userInvited: 'ARTIST')">
    <a
      class="submenu-item-main--preserveCasing menu-item-main--hasIcon"
      routerLink="/{{invite.inviteOwner.slug}}"
      routerLinkActive="submenu-item-main--active"
      (click)="menuClosed.emit()">
      <i class="submenu-item-icon {{brandedIcons.listItem}}"></i>
      <span class="submenu-item-text">{{invite.inviteOwner.name}}</span>
      <span
        *ngIf="invite.currentState.state === 'PENDING'"
        class="submenu-item-badge"
        title="Je bent uitgenodigd"
        i18n-title
      ></span>
      </a>
  </li>
</ng-template>

<ng-template #organisationProfiles>
  <li class="submenu-item" *ngFor="let invite of (currentUser?.invites?.items | userInvited: 'ORGANISATION')">
    <a
      class="submenu-item-main--preserveCasing menu-item-main--hasIcon"
      routerLink="/{{invite.inviteOwner.slug}}"
      routerLinkActive="submenu-item-main--active"
      (click)="menuClosed.emit()">
      <i class="submenu-item-icon {{brandedIcons.listItem}}"></i>
      <span class="submenu-item-text">{{invite.inviteOwner.name}}</span>
      <span *ngIf="invite.currentState.state === 'PENDING'" class="submenu-item-badge" title="Je bent uitgenodigd"></span>
      </a>
  </li>
</ng-template>

<ng-template #pendingCalls>
  <li
    class="submenu-item"
    *ngFor="let invite of (currentUser?.invites?.items | userInvited:'ORGANISATION_CALL':true) | orderBy:'asc':'datePublished'">
    <a
      class="submenu-item-main--preserveCasing menu-item-main--hasIcon"
      routerLink="/{{invite.inviteOwner.organisationSlug}}/calls/{{invite.inviteOwner.slug}}"
      routerLinkActive="submenu-item-main--active"
      (click)="menuClosed.emit()"
      title="{{invite.inviteOwner.title}}">
      <i class="submenu-item-icon {{brandedIcons.listItem}}"></i>
      <span class="submenu-item-text">{{invite.inviteOwner.title}}</span>
      <span
        class="submenu-item-badge"
        title="Je bent uitgenodigd"
        i18n-title
      ></span>
      </a>
  </li>
</ng-template>

<ng-template #acceptedCalls>
  <li
    class="submenu-item"
    *ngFor="let organisationCall of currentUser?.acceptedOrganisationCalls?.items | slice:0:numberOfAcceptedOrganisationCallsInMenu">
    <a
      class="submenu-item-main--preserveCasing menu-item-main--hasIcon"
      routerLink="/{{organisationCall.organisation.slug}}/calls/{{organisationCall.slug}}"
      routerLinkActive="submenu-item-main--active"
      (click)="menuClosed.emit()"
      title="{{organisationCall.title}}">
      <i class="submenu-item-icon {{brandedIcons.listItem}}"></i>
      <span class="submenu-item-text">{{organisationCall.title}}</span>
    </a>
  </li>
</ng-template>